const mainNav: HTMLElement = document.querySelector('.main-nav')!;
const navToggle: HTMLElement = document.querySelector('.nav-toggle')!;
const navWrapper: HTMLElement = document.querySelector('.nav-wrapper')!;

navToggle.addEventListener('click', () => {
  mainNav.classList.toggle('expand-mobile-nav');
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => anchor.addEventListener('click', (event) => {
  let eventTarget = <HTMLAnchorElement>event.target;
  // the target might be an element inside of the anchor tag, so we need to grab the actual anchor tag
  while (eventTarget.parentElement && eventTarget.tagName !== 'A') {
    eventTarget = <HTMLAnchorElement>eventTarget.parentElement;
  }

  const scrollTargetSelector: string = eventTarget?.getAttribute('href') || '';
  if (!scrollTargetSelector) {
    return;
  }

  if (scrollToSelector(scrollTargetSelector)) {
    event.preventDefault();
    history.replaceState(null, '', document.location.pathname + scrollTargetSelector);
  }
}));

function scrollToSelector(selector: string): boolean {
  const elem = document.querySelector(selector);
  if (!elem) {
    return false;
  }

  const scrollToPosition = elem.getBoundingClientRect().top +
    document.documentElement.scrollTop -
    navWrapper.clientHeight;

  window.scrollTo({
    top: scrollToPosition,
    behavior: 'smooth',
  });

  mainNav.classList.remove('expand-mobile-nav');

  return true;
}
